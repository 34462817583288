<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

/**
 * Dashboard Component
 */
export default {
    page: {
    title: "Utilisateurs",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Utilisateurs",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Utilisateurs",
          active: true,
        },
        {
          text: "Un utilisateur",
          active: true,
        },
      ],
      user: {},
      transactions: [],
      id: "",
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('users/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        
        this.user = data
      })
    },
    getTransactions() {
      fetch(Helper.route('transactions/of-user/'+this.id), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log("transactions : ",data);
        this.transactions = data
      })
    },
  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
    this.getTransactions()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    
    <div class="row" v-if="user.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">À propos de l'utilisateur</h4>
            <div class="row mt-4">
              <div class="col-md-3">
                <dl>
                  <dt>Nom de Famille</dt>
                  <dd>{{ user.last_name }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Prénom(s)</dt>
                  <dd>{{ user.first_name }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Sexe</dt>
                  <dd>{{ user.gender == true ? "Masculin" : "Féminin" }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Email</dt>
                  <dd>{{ user.email }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Téléphone</dt>
                  <dd>{{ user.phone }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Ville</dt>
                  <dd>{{ user.city }}</dd>
                </dl>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>
